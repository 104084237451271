<template>
  <div id="video-container"></div>
</template>
<script>
  import {EZUIKitPlayer} from "ezuikit-js";
  export default {
    name: "Video",
    mounted() {
      this.init();
    },
    methods: {

      init() {
        this.$http.get();
        new EZUIKitPlayer({
          id: "video-container", // 视频容器ID
          accessToken:
            "at.bb3p5j9td4exe3s3dmexmxmr72vqxw5w-2rvalw1zur-1yi2sdc-nzv4dz9fm1",
          url: "ezopen://open.ys7.com/FR0449316/1.live",
          width: 600,
          height: 400,
          handleError: (err) => {
            if (err.type === "handleRunTimeInfoError" && err.data.nErrorCode === 5) {
              // 加密设备密码错误
            }
          },
        });
      },
    },

  };
</script>



<style scoped lang="scss">

</style>
